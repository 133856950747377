
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    docData: Object,
  },
  setup() {
    function viewCourse() {
      // console.log(111);
    }
    function subStrDoc(str: string) {
      if (str.length > 200) {
        return str.substr(0, 200);
      }
      return str;
    }
    return {
      viewCourse,
      dateFormat,
      subStrDoc,
    };
  },
});
