<template>
  <transition-group tag="ul" name="list" class="doc-ul">
    <li v-for="item in docData.data" :key="item.id">
      <router-link :to="{ name: 'DocDetail', query: { docId: item.id } }">
        <div class="doc-title">
          <img class="doc-type-icon" :src="item.libIcon" alt="" />

          <div class="doc-subject">

            <div>{{ item.docTitle }}</div>

<!--            <div class="doc-author">{{item.author}}</div>-->
            <router-link
                    :to="{ name: 'Personal', query: { uid: item.userId } }"
                    class="doc-author"
            >
              {{ item.author }}
            </router-link>
          </div>

        </div>
        <div class="doc-description">
          {{ subStrDoc(item.docIntroduction) }}
        </div>
        <div class="doc-info">
          <div class="doc-date">
            <!--<img src="@/assets/img/docItem/icon91.png" alt="" />-->
            {{ dateFormat(item.createTime) }}
          </div>
          <div class="doc-view">
            <!-- <img src="https://www.bzwz.com/static/m/images/icon92.png" alt="" /> -->
            <img src="@/assets/img/common/icon27.png" />
            {{ item.clickCount }}
          </div>
        </div>
      </router-link>
    </li>
  </transition-group>
</template>



<script lang="ts">
import { defineComponent } from "vue";
import dateFormat from "../../utils/dateFormat";
import axios from "axios";
export default defineComponent({
  name: "ClassItem",
  props: {
    docData: Object,
  },
  setup() {
    function viewCourse() {
      // console.log(111);
    }
    function subStrDoc(str: string) {
      if (str.length > 200) {
        return str.substr(0, 200);
      }
      return str;
    }
    return {
      viewCourse,
      dateFormat,
      subStrDoc,
    };
  },
});
</script>


<style lang="scss" scoped>
/*文库 start*/
.doc-ul {
  width: 355px;
  margin: 0 auto;
}
.doc-ul li {
  display: block;
  width: 100%;

  box-sizing: border-box;

  padding: 20px 10px 15px 10px;
  border-bottom: 1px solid #eee;
}

.doc-title {
  display: block;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;


}

.doc-title img {
  width: 20px;
  height: 20px;
  transform: translateY(-2px);
  vertical-align: middle;

  float: left;
  position: relative;
  top: 4px;
}

.doc-subject {
  padding-left: 0.7rem;
}
.doc-author {
  color: #20c2d6;
  font-size: 0.37333rem;
  /*display: block;*/
  /*margin-bottom: 0.26667rem;*/
}

.doc-description {
  color: #666;
  font-size: 15px;
  word-break: break-all;
  display: block;
  margin-top: 0.26667rem;
  padding-left: 0.7rem;
}

.doc-description p {
  margin: 0;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.doc-info {
  margin-top: 10px;
  color: #999999;
  font-size: 14px;
  display: flex;
  // justify-content: flex-start;
  justify-content: space-between;

  padding-left: 0.7rem;
}
.doc-info .doc-date {
  margin-right: 25px;
  display: inline-flex;
  align-items: center;
}
.doc-info .doc-date img {
  /* vertical-align: middle; */
  width: 13.5px;
  height: 13.5px;
  margin-right: 5px;
}
.doc-info .doc-view {
  float: right;
  color: #999;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
}
.doc-info .doc-view img {
  width: 25px;
  height: 15px;
  vertical-align: middle;
  margin-right: 5px;
}

/*文库 end*/
</style>
